var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, useRef, } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Sidebar from '@shared/ui/sidebars/Sidebar';
import AvatarImage from '@shared/ui/display/AvatarImage';
import { cancelProfileEdit, } from '@entities/profile';
import { ContainerRow } from '@components/styled';
import EditBadge from '@shared/ui/badges/EditBadge';
import DebounceInput from '@shared/ui/inputs/DebounceInput';
import Input from '@shared/ui/inputs/Input';
import Textarea from '@shared/ui/inputs/Textarea';
import SocialsEditor from '@components/profile/components/SocialsEditor';
import Button from '@shared/ui/buttons/Button';
import useNicknameCreation from '@entities/profile/api/useNicknameCreation';
import { ReactComponent as SaveIcon } from '@icons/wolfkit-light/floppy-disk-light.svg';
import { useAppDispatch } from '@app/store/Hooks';
import { CompleteProfileBanner } from '@features/profile/profile-info';
import { LocationSelect } from '@features/countries';
import { useToastNotification } from '@shared/ui/overlay/Toast';
import validationSchema from './validation';
import { AboutContainer, Avatar, ButtonsContainer, FieldLabel, Form, FormInnerContainer, InputColumn, InputFieldsRow, InputsContainer, NicknameContainer, ProfileEditContainer, SelectCaretDownIcon, SocialsColumn, } from './components';
import ProfileAvatarEditor from '../ProfileAvatarEditor';
import useProfileEdit from '../../api/useProfileEdit';
const ProfileEdit = () => {
    var _a, _b, _c, _d, _e;
    const { t } = useTranslation();
    const { toast } = useToastNotification();
    const dispatch = useAppDispatch();
    const { updateProfile, isUpdatingProfile, formInitialValues, } = useProfileEdit();
    const initialValues = formInitialValues || {
        username: '',
        displayName: '',
        description: '',
        contacts: {},
        initialUsername: '',
        initialImage: undefined,
        imageEditorState: {
            isOpen: false,
            prevStep: 0,
            initialImage: '',
        },
        isTraderProfile: false,
        // before image apply we consider that its null
        image: null,
        displayCompleteProfile: false,
    };
    const [displayCompleteProfile, setDisplayCompleteProfile] = useState(initialValues.displayCompleteProfile);
    const editorRef = useRef(null);
    const { control, handleSubmit, setValue, watch, getValues, setError, formState: { errors }, trigger, } = useForm({
        defaultValues: initialValues || {},
        resolver: yupResolver(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        validationSchema(t)),
    });
    const { findByNickname, nicknameInputStatus, nicknameOnValidate, } = useNicknameCreation({ getValues, setError, trigger });
    const profileImage = watch('image.avatarImg');
    const initialAvatar = watch('initialImage.avatar');
    const isEditorOpen = watch('imageEditorState.isOpen');
    const publicName = watch('displayName');
    const location = watch('location');
    const hasPublicNameError = typeof ((_a = errors === null || errors === void 0 ? void 0 : errors.displayName) === null || _a === void 0 ? void 0 : _a.message) !== 'undefined';
    const isPublicNameFilled = (publicName === null || publicName === void 0 ? void 0 : publicName.length) > 0 && !hasPublicNameError;
    const isLocationFilled = Boolean(location && (location === null || location === void 0 ? void 0 : location.length) > 0 && typeof ((_b = errors === null || errors === void 0 ? void 0 : errors.location) === null || _b === void 0 ? void 0 : _b.message) === 'undefined');
    const haveNicknameNotifications = typeof ((_c = errors === null || errors === void 0 ? void 0 : errors.username) === null || _c === void 0 ? void 0 : _c.message) !== 'undefined' || typeof (nicknameInputStatus === null || nicknameInputStatus === void 0 ? void 0 : nicknameInputStatus.text) !== 'undefined';
    const nickname = watch('username');
    const nicknameOnChange = (event) => {
        setValue('username', event.target.value);
    };
    const handleSocialsChange = (socials) => {
        setValue('contacts', socials);
    };
    const onSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield updateProfile(values);
        if (res === true) {
            dispatch(cancelProfileEdit());
        }
        else {
            toast({
                message: t('errors.internal_server_error', { ns: 'common' }),
                variant: 'error',
                displayStyle: 'solid',
            });
        }
    });
    const onCancel = () => {
        dispatch(cancelProfileEdit());
    };
    const openImageEditor = () => {
        if (getValues('imageEditorState.initialImage')) {
            setValue('imageEditorState.prevStep', 2);
        }
        setValue('imageEditorState.isOpen', true);
    };
    const handleClose = () => {
        if (editorRef === null || editorRef === void 0 ? void 0 : editorRef.current) {
            editorRef.current.handleClose();
            setValue('imageEditorState.isOpen', false);
        }
    };
    const onSocialsLeave = () => {
        trigger('contacts');
    };
    const closeCompleteProfileBanner = () => {
        setDisplayCompleteProfile(false);
    };
    useEffect(() => {
        findByNickname({ nickname });
    }, []);
    return (_jsxs(ProfileEditContainer, { children: [displayCompleteProfile && (_jsx(CompleteProfileBanner, { onClose: closeCompleteProfileBanner, isLocationFilled: isLocationFilled, isPublicNameFilled: isPublicNameFilled })), _jsxs(Form, { onSubmit: handleSubmit(onSubmit), children: [_jsxs(FormInnerContainer, { children: [_jsx(EditBadge, { onClick: openImageEditor, disabled: isUpdatingProfile, children: _jsx(Avatar, { children: _jsx(AvatarImage, { publicName: initialValues.displayName, imageUrl: profileImage || initialAvatar, size: 'medium' }) }) }), _jsxs(ContainerRow, { children: [_jsxs(InputsContainer, { children: [_jsxs(InputFieldsRow, { haveInputErrors: hasPublicNameError, children: [_jsx(InputColumn, { children: _jsx(Controller, { name: 'displayName', control: control, render: ({ field, fieldState }) => {
                                                                var _a, _b;
                                                                return (_jsx(Input, { label: t('profile.username'), value: field.value, ref: field.ref, onChange: field.onChange, onBlur: field.onChange, notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: typeof ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) === 'string' ? 'error' : undefined, disabled: isUpdatingProfile, maxCount: 30 }));
                                                            } }) }), _jsxs(InputColumn, { children: [_jsx(FieldLabel, { children: t('profile.location') }), _jsx(Controller, { name: 'location', control: control, render: ({ field }) => (_jsx(LocationSelect, { value: field.value, onChange: field.onChange, IconComponent: SelectCaretDownIcon, disabled: isUpdatingProfile })) })] })] }), _jsx(NicknameContainer, { haveNotifications: haveNicknameNotifications, children: _jsx(DebounceInput, { label: t('profile.nickname'), value: nickname, onChange: nicknameOnChange, searchFn: nicknameOnValidate(), debounceDelay: 500, message: nicknameInputStatus === null || nicknameInputStatus === void 0 ? void 0 : nicknameInputStatus.text, state: nicknameInputStatus === null || nicknameInputStatus === void 0 ? void 0 : nicknameInputStatus.status, notificationStatus: typeof ((_d = errors.username) === null || _d === void 0 ? void 0 : _d.message) === 'string' ? 'error' : undefined, notification: (_e = errors.username) === null || _e === void 0 ? void 0 : _e.message, maxCount: 30, startAdornment: '@', disabled: isUpdatingProfile }) }), _jsx(AboutContainer, { children: _jsx(Controller, { name: 'description', control: control, render: ({ field }) => {
                                                        var _a;
                                                        return (_jsx(Textarea, { label: t('profile.about'), variant: 'outlined', hiddenLabel: true, multiline: true, minRows: 4, maxRows: 4, maxCount: 250, value: field.value, onChange: field.onChange, onBlur: field.onBlur, notificationStatus: typeof ((_a = errors.description) === null || _a === void 0 ? void 0 : _a.message) === 'string' ? 'error' : undefined, ref: field.ref, disabled: isUpdatingProfile }));
                                                    } }) })] }), _jsx(SocialsColumn, { children: _jsx(SocialsEditor, { label: t('profile.social'), initialValues: initialValues.contacts, limit: 7, limitExceedText: t('profile.messages.max_socials_links'), errors: errors === null || errors === void 0 ? void 0 : errors.contacts, onChange: handleSocialsChange, onBlur: onSocialsLeave, disabled: isUpdatingProfile }) })] })] }), _jsxs(ButtonsContainer, { children: [_jsx(Button, { variant: 'filled', color: 'primary', size: 'small', type: 'submit', startIcon: SaveIcon, isLoading: isUpdatingProfile, disabled: isUpdatingProfile, fullWidth: true, children: t('actions.save_changes', { ns: 'common' }) }), _jsx(Button, { variant: 'tinted', color: 'error', size: 'small', type: 'button', isLoading: isUpdatingProfile, disabled: isUpdatingProfile, onClick: onCancel, fullWidth: true, children: t('actions.cancel', { ns: 'common' }) })] })] }), _jsx(Sidebar, { "aria-hidden": !isEditorOpen, onClose: handleClose, isOpen: Boolean(isEditorOpen), shouldCloseOnOutsideClick: true, destroyOnHide: true, children: _jsx(ProfileAvatarEditor, { setValue: setValue, getValues: getValues, watch: watch, initialStep: getValues('imageEditorState.prevStep'), ref: editorRef }) })] }));
};
export default ProfileEdit;
